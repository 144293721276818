let breadcrumbsColor = {

    init: function () {
        let color = "";
        let whiteActiveColor = false;


        const $target = $('h1').first();
        if ( $target.length ){
            color = $target.css('color');
            if(color != "rgb(0, 0, 0)" && color != "rgb(22, 22, 22)" && color != "rgb(2, 2, 2)"){
                whiteActiveColor = true;
            }
        }


        let $breadcrumbsElement = $('#breadcrumb');
        if($breadcrumbsElement != null) {
            if( whiteActiveColor ) {
                $breadcrumbsElement.removeClass('breadcrumb--black');
                $breadcrumbsElement.addClass('breadcrumb--white');
            }
        }

    },

};

$(document).ready(function () {
    breadcrumbsColor.init();
});
